/* Sass Document */


@import url('https://fonts.googleapis.com/css?family=Raleway:300,400');


h1, h2, h3, {font-weight: 400;}


h3:before {content:"| ";

   color: $primary-color;
   font-weight: bold;

}



.is-dropdown-submenu-parent {min-width: rem-calc(150);}
.is-dropdown-submenu-item,  a {color:#000;}
.is-dropdown-submenu-parent a {color:#000;}
.is-not-parent a {color:#000;}
.no-padding {padding-left:0px!important;
padding-right:0px!important;}

#mobile-menu ul li a:before{content:"- ";}

.stat a{color:$primary-color;}

$marketing-site-footer-bg: #2c3840;

.marketing-site-footer {
  background: $marketing-site-footer-bg;
  color: lighten($marketing-site-footer-bg, 40%);
  padding: 2rem 0 0;

  .marketing-site-footer-menu-social a {
    color: $white;
  }

  .column-block {
    margin-bottom: 30px;
  }

  > .row {
    margin-bottom: 1rem;
  }

  @include breakpoint(small only) {
    .columns {
      margin-bottom: 2rem;
    }
  }
}

.marketing-site-footer-name {
  color: $white;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.marketing-site-footer-title {
  color: $white;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.marketing-site-footer-block {
  display: flex;
  margin-bottom: 1rem;

  .fa {
    font-size: 2rem;
    color: darken($marketing-site-footer-bg, 20%);
  }

  p {
    margin-left: 1rem;
    line-height: 1.125rem;
  }
}

.marketing-site-footer-bottom {
  background: darken($marketing-site-footer-bg, 20%);
  padding: 1rem 0;

  p,
  .menu {
    margin-bottom: 0;
  }

  .marketing-site-footer-bottom-links {
    justify-content: flex-end;

    a {
      color: lighten($marketing-site-footer-bg, 40%);
    }

    a:hover { color:$white;

    }
    @include breakpoint(medium down) {
      justify-content: center;
    }
  }

  @include breakpoint(medium down) {
    text-align: center;
  }
}





/* Video header */

.homepage-hero-module {
    border-right: none;
    border-left: none;
    position: relative;

}
.no-video .video-container video,
.touch .video-container video {
    display: none;
}
.no-video .video-container .poster,
.touch .video-container .poster {
    display: block !important;
}
.video-container {
    position: relative;
    bottom: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #000;
}
.video-container .poster img {
    width: 100%;
    bottom: 0;
    position: absolute;
}
.video-container .filter {
    z-index: 100;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
}
.video-container video {
    position: relative;
    z-index: 0;
    bottom: 0;
}
.video-container video.fillWidth {
    width: 100%;
}

.title-container {
	z-index: 1000;
position: absolute;
top: 35%;
width: 100%;
text-align: center;
color: #fff;
}

.title-image{

	position: relative;
top: -150px;
	width: 45%;
}


/* Social bar */

.social-bar {

background-color: #f6f6f6;
	padding:0em !important;
}



/* Profile / Service card */

$profile-card-about-text-transform: uppercase;
$profile-card-about-separator-width: 3rem;
$profile-card-about-separator-thickness: 0.125rem;

.profile-card-action-icons {
  background-color: $primary-color;
  font-weight: 400;
  margin-bottom: 1.6rem;
  border-radius: 0.125rem;
  box-shadow: 0 0.0625rem 0.1875rem rgba(0,0,0, 0.18), 0 0.125rem 2px rgba(0,0,0, 0.36);

  .card-section {
    background-color: inherit;
    padding: 0.5rem 0 0;

    .profile-card-header, {
      padding: 0.7rem 1rem;
      background-color: $white;
      border-bottom: .0625rem solid $medium-gray;
    }


    .profile-card-about {
      border-bottom: .0625rem solid $medium-gray;
      padding: 0.7rem 1rem;
      background-color: $white;
    }

    .profile-card-header {
      display: flex;
      align-items: flex-start;

      .profile-card-avatar {
        margin-right: 0.5rem;

        .avatar-image {
          border-radius: 50%;
          width: rem-calc(60);
          height: rem-calc(60);
        }
      }

      .profile-card-author {
        flex: 1 0 0;
        margin-top: 0.3rem;
        padding-bottom: 0;

        .author-title,
        .author-description {
          margin-bottom: 0;
        }

        .author-title {
          color: $black;
        }

        .author-description {
          color: $dark-gray;
          font-size: 0.9em;
        }
      }
    }

    .profile-card-about {
      .about-title {
        text-transform: $profile-card-about-text-transform;
      }

      .separator-left {
        text-align: left;
        @include clearfix;

        &::after {
          position: relative;
          width: $profile-card-about-separator-width;
          border-bottom: $profile-card-about-separator-thickness solid $primary-color;
          margin: 0.3rem auto 0;
          margin-left: 0;
        }
      }

      .about-content {
        margin-bottom: 0;
      }

      .about-skills {
        margin-top: 0.6rem;

        ul.arrow {
          list-style-type: none;
          padding-left: 0;
          margin-left: 0;
          margin-bottom: 0;

          li {
            &:before {
              content: '\f006';
              font-family: 'FontAwesome';
              float: left;
              color: $primary-color;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }

    .profile-card-action {
      display: flex;

      .action-area {
        width: 20%;
        border-right: 0.0625rem solid $medium-gray;
        text-align: center;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;

        &:last-of-type {
          border-right: none;
        }

        .action-anchor {
          &.has-tip {
            border-bottom: none;
            cursor: pointer;
          }

          .fa {
            color: $primary-color;
          }
        }
      }
    }
  }
}



.header-image{
	min-height: 300px;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
	margin-bottom: 1em;

}


/*hover */

/* Underline From Center */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #96c63e;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}


.hvr-bounce-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}




/* Page fader */

.content { -webkit-animation:fadein 0.7s; animation:fadein 0.7s }
@-webkit-keyframes fadein { from {opacity:0} to {opacity:1} }
@keyframes fadein { from {opacity:0} to {opacity:1} }


/* slider */

.testimonial-slider-container {
  background: #fff;;
  padding: 1rem 1rem;

  .orbit-previous,
  .orbit-next {
    color: $black;

    &:hover, &:active, &:focus {
      background-color: transparent;
    }
  }

  .orbit-previous {
    left: -1rem;
  }

  .orbit-next {
    right: -1rem;
  }

  .testimonial-slide {
    display: flex;
    justify-content: center;
  }

  .testimonial-slide-content {
    padding: 1rem 1.5rem;

    @media screen and (min-width: 40em) {
      padding: 1rem 4rem;
    }
  }

  .profile-pic {
    max-width: 225px;
  }

  .testimonial-slide-text {
    padding-left: 3rem;
  }

  .testimonial-slide-quotation {
    font-size: 1.2rem;
    position: relative;

    &::before {
      content: "“";
      font-size: 6rem;
      color: lighten($black, 80%);
      position: absolute;
      top: -3rem;
      left: -3rem;
    }
  }

  .testimonial-slide-author-container {
    display: flex;

    @include breakpoint(small only) {
      .testimonial-slide-author-info {
        margin-left: 1rem;
      }
    }
  }
}




.marketing-site-content-section {
  display: flex;
  flex-wrap: wrap;

  .button.round {
    border-radius: 5000px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .marketing-site-content-section-img {
    align-self: center;
    flex: 1 0 100%;

    img {
      width: 100%;
    }

    @media screen and (min-width: 40em) {
      flex: 1 0 50%;
    }
  }

  .marketing-site-content-section-block {
    padding: 1rem;
    flex: 1 0 100%;
    align-self: stretch;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media screen and (min-width: 40em) {
      flex: 1 0 50%;
      padding: 2rem;
    }
  }
}

.marketing-site-content-section-block-header {
  font-size: 24px;
}

.marketing-site-content-section-block-subheader {
  font-size: 13px;
}


.header-title {

	text-shadow: 1px 1px #000;
	margin: 0 auto;
text-align: center;
	padding-top: 150px;
	color: #fff;
font-size: 4rem;
	font-family: 'montserrat';
text-transform: uppercase;

}
